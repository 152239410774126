.title {
  font-size: 2em;
}

.heading {
  font-size: 1.2em;
}

.large {
  font-size: 1.1em;
}

.body {
  font-size: 1em;
}

.small {
  font-size: 0.9em;
}

.mini {
  font-size: 0.8em;
}

.superMini {
  font-size: 0.7em;
}

.primaryFont {
  font-family: "cairo";
}

.secondaryFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.singleLine {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: auto;
}
